import React from 'react'
import PropTypes from 'prop-types';

import Layout from '../../components/layoutReport'

import { ReportPreviewAPIProvider } from './../../services/reportPreview';

const ReportPreviewLayout = ({id, children}) => {

  return (
    <Layout>
      <ReportPreviewAPIProvider id={id}>
        {children}
      </ReportPreviewAPIProvider>
    </Layout>
  )
}

ReportPreviewLayout.propTypes = {
  children: PropTypes.element,
  id: PropTypes.string
}

export default ReportPreviewLayout
