import React, { useContext } from 'react'
import { Router } from "@reach/router"

import ReportPreview from './pages/reportPreview';

const ReportPreviewIndex = ({ id }) => {
  return (
    <Router>
      <ReportPreview path={'/report-preview/:client'} id={id}/>
    </Router>
  )
}

export default ReportPreviewIndex
