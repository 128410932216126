import React, { useContext, useEffect } from 'react'

// import ReportPreviewLayout from '../../layoutReport'

import ReportPreviewAPIContext from '../../../services/reportPreview';

const Header = ({ name, folder, date}) => (
  <header>
    <div className="header-content">
      <span className="header-content--name">{name}</span>
      <span className="header-content--folder"># {folder}</span>
      <span className="header-content--date">{date}</span>
    </div>
  </header>
)

const Footer = () => (
  <footer>
    <div className="footer--name">
      Johnson O'Connor Research Foundation
    </div>
    <div className="footer--link">
      jocrf.org
    </div>
  </footer>
)

const Paged = ({ title, children  }) => (
  <div className="report-page">
    <h1>{title}</h1>
    {children}
  </div>
)

const Primary = ({ careers, children }) => (
  <div className="report">
    <div className="report-region-primary">
      <div className="report-stack">
        {children}
        <div className="careers primary-careers">
          <h3>Career Fields to Consider</h3>
          <ul>
            {careers.map((career, index) => (
              <li key={`primary-career-${index}`}>{career}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)
const PrimaryDiscussion = ({ title, children }) => (

  <div>
    <h2>{title}</h2>
    <div
      className="report-content"
      dangerouslySetInnerHTML={{ __html: children }}
    />
  </div>
)

const PrimaryCareers = ({ careers }) => (
  <div className="report-stack">
    <div className="careers primary-careers">
      <h3>Career Fields to Consider</h3>
      <ul>
        {careers.map((career, index) => <li key={`primary-career-${index}`}>{career}</li>)}
      </ul>
    </div>
  </div>
)


const Discussion = ({ title, children }) => (
  <div className="report">
    <div className="report-region">
      <div className="report-row">
        <h2>{title}</h2>
        <div
          className="report-content"
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </div>
    </div>
  </div>
)

const content = {
  primary: {
    discussion: [
      {
        title: 'Structural Visualization and Objective Personality',
        body: `
          <p>You are a natural three-dimensional thinker, who thrives in collaborative roles.
          Consider leadership and client-facing positions in technical, scientific, or design-based fields.</p>`
      }
    ],
    careers: [
      'Technical sales, marketing',
      'Computer-aided design (CAD)',
      'Surveying',
      'Environmental, earth sciences',
      'Life sciences',
      'Physical therapy',
      'Engineering',
      'Architecture, interior design',
      'Industrial design',
      'Software design'
    ]
  },
  emphasis: [
    {
      title: 'Sound',
      careers: [
        'Sound engineering',
        'Acoustical engineering',
        'Foley arts',
        'Sound technology',
        'Music composition',
        'Conducting'
      ]
    }
  ],
  discussion: [
    {
      title: 'Silograms',
      body: '<p>Your mind seems to naturally retain new words, which is what our Silograms test measures—a gift for word learning. This ability can be helpful in almost any career, since nearly every industry has its own specialized terminology, but it also has obvious benefits in school, as much of our formal education involves memorization of terms and vocabulary. Programmers score high here, likely because they must remember the syntax of various programming languages. Branches of science like botany or biology are vocabulary-heavy and could be easier to learn for someone with this ability. You may also find it easy to learn new words in a foreign language, so consider roles that let you work internationally or with international populations like global medicine, international real estate development, archaeology, or global urban planning. Fluency in a second language is a desirable asset in our world; capitalize on your Silograms aptitude to give yourself a competitive edge in the job market.</p>'
    },
    {
      title: 'Numerical Reasoning',
      body: `
        <p>The origins of mathematical thought are prehistoric.
        Your score on our Number Series test would indicate that your mind may naturally speak this early language.
        Number Series measures the ability to analyze and find patterns in numbers.
        You won’t be surprised to hear that this aptitude is crucial in fields that involve working with numerical information like finance, statistics, and economics.&nbsp;</p>
        <p>Math plays an integral role in many spatial fields. You may use these aptitudes in calculating and interpreting the equations in physics and engineering, analyzing statistics as a data or research scientist, or in determining costs in an interior design or real estate development project.</p>
        <p>Even in fields where numbers are not the focus, this aptitude could still come into play in tasks like budgeting, forecasting, setting prices, or when understanding and keeping up-to-date in the latest research in your field. Try personal investing, or take charge of your household’s budget as ways to use this ability outside of work.&nbsp;</p>
      `
    },
    {
      title: 'Tonal Memory',
      body: `
        <p>If songs stick in your head effortlessly, you now know why—you have a natural gift for <strong>Tonal Memory</strong>. This test measures an ability to remember a sequence of notes and, of all our auditory aptitudes, is the most correlated with success playing an instrument. This might be particularly helpful for learning instruments that play melodies like the piano or flute, and could even be helpful for learning lyrics to songs. This aptitude is, as you might expect, strongly associated with careers involving music. Professional musicians, music composers, and musicologists tend to have high scores in this area. If you’ve ever daydreamed about starting a band, it’s no surprise. Keep in mind that working with music doesn’t have to mean pursuing the life of a performer;
        consider other ways in which you might make music part of your career.&nbsp;</p>
        <p>Auditory aptitudes are, as you might expect, strongly associated with careers involving music, but, keep your Structural Visualization aptitude in mind.
        You might prefer composing your own music to simply playing someone else’s. Many careers in music production require working with 3D technology, like sound design, sound engineering, or audio technology. You could use this combination in acoustic architecture, designing the sound environment of a space, or develop music streaming products or language-related technology. Sound also plays a part in studies of the natural world like ecology, biology, and environmental science. Play an instrument, go to concerts, or study to a soundtrack—a life without music may seem unfathomable to you.</p>
      `
    }
  ]
}

const IndexPage = ({id}) => {
  const ReportPreviewAPI = useContext(ReportPreviewAPIContext);

  useEffect(() => {
    ReportPreviewAPI.injectReportStyles();
  }, [ReportPreviewAPI.data])

  return (
    <div dangerouslySetInnerHTML={{ __html: ReportPreviewAPI.data ? ReportPreviewAPI.getReportPreviewHTML() : ''}} />
  )
}

export default IndexPage
